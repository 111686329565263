package io.data2viz.core

class namespace {
    companion object {
        val svg = "http://www.w3.org/2000/svg"
        //        val     xhtml: d3_nsXhtml,
        val xlink = "http://www.w3.org/1999/xlink"
        val xml = "http://www.w3.org/XML/1998/io.data2viz.core.namespace"
        val xmlns = "http://www.w3.org/2000/xmlns/"
    }
}
